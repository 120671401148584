@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("./assets/fonts/Gilroy-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: bold;
  src: url("./assets/fonts/Gilroy-Bold.otf") format("opentype");
}
